import axios from 'axios';
import store from "../store/index";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL + '/api/v1',
});

axiosInstance.interceptors.request.use(function (config) {
    let token = window.localStorage.getItem(store.state.local_storage_access_token_key);
    let type = window.localStorage.getItem(store.state.local_storage_token_type_key);

    if (token && type) {
        config.headers['Authorization'] = `${type} ${token}`;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default {
    install(Vue) {
        Vue.prototype.$axios = axiosInstance;
    },
};

export {axiosInstance};

