import {LOGIN_IF_AUTHENTICATED} from './actionTypes';
import store from './store/index';
import router from './router/index';
import Helper from './helpers/Helper';
import User from './helpers/User';
import ObjectHelper from './helpers/ObjectHelper';
import Axios from './helpers/Axios';
import App from './App'
import CoreuiVue from '@coreui/vue'
import icons from './icons/icons.js'
import Vue from 'vue';
import VueMeta from 'vue-meta'
import ConfigHelper from './helpers/ConfigHelper';

require('vue');


// Core-ui
Vue.config.performance = true
Vue.use(CoreuiVue)

// Vue use prototypes to call with: this.$helper | this.$user | this.$object
Vue.use(Helper);
Vue.use(User);
Vue.use(ObjectHelper);
Vue.use(ConfigHelper);
Vue.use(Axios);

Vue.use(VueMeta);
Vue.use(require('vue-moment'));

// Check if user is authenticated and retrieve it
store.dispatch(LOGIN_IF_AUTHENTICATED).finally(() => {
    new Vue({
        el: '#app',
        router,
        store,
        icons,
        template: '<App/>',
        components: {
            App
        }
    });
});
