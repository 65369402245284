<template>
  <div class="alert-container">
    <CAlert v-for="(alert, index) in alerts" :key="index" :color="alert['color']" closeButton :show.sync="alert.timer" v-if="alert.timer >= 0">
      <div v-html="alert.text"></div>
      <div class="font-italic text-right font-xs" v-if="alert.show_alert_disappear_message">
        The alert will disappear in {{ alert.timer }} secs.
      </div>
      <CProgress
          v-if="alert.show_progress === true"
          :max="getMaxValue(index, alert.timer)"
          :value="alert.timer"
          height="3px"
          :color="alert['color']"
          animated
      />
    </CAlert>
  </div>
</template>

<script>
export default {
  name: 'Alerts',
  data() {
    return {
      timers: {},
    };
  },
  computed: {
    alerts() {
      return this.$store.state.alerts;
    }
  },
  methods: {
    getMaxValue(index, max) {
      if (!this.timers[index]) {
        this.timers[index] = max;
      }
      return this.timers[index];
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-container {
  position: fixed;
  right: 10px;
  top: 10px;
  ::v-deep ul {
    padding-left: 10px;
  }
}
</style>
