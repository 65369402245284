class Helper {
    constructor() {
        // Do stuff
    }

    nl2br(str) {
        if (typeof str !== "string") return "";
        return str.replace(/\n/g, "<br>");
    }

    log(str) {
        console.log(str);
    }

    round_number(num, scale = 2) {
        if (Math.round(num) !== num) {
            // if (Math.pow(0.01, scale) > Math.abs(num)) {
            //     return 0;
            // }
            let sign = Math.sign(num);
            let arr = ("" + Math.abs(num)).split('.');
            if (arr.length > 1) {
                if (arr[1].length > scale) {
                    let integ = +arr[0] * Math.pow(10, scale);
                    let dec = integ + (+arr[1].slice(0, scale) + Math.pow(10, scale));
                    let proc = +arr[1].slice(scale, scale + 1)
                    if (proc >= 5) {
                        dec = dec + 1;
                    }
                    dec = sign * (dec - Math.pow(10, scale)) / Math.pow(10, scale);
                    return dec;
                }
            }
        }
        return num;
    }

    generateFormData(data) {
        data = this.object_keys_with_brackets_for_formdata(data);
        const formData = new FormData();
        for (let [key, value] of Object.entries(data)) {
            if (typeof value === 'boolean') {
                value = 0 + Boolean(value);
            }
            if (value === null) {
                value = ''
            }
            formData.append(key, value);
        }
        return formData;
    }

    object_keys_with_brackets_for_formdata(object, prepend = '') {
        let realObject = object;

        // transform array as index:value object
        if (Array.isArray(object)) {
            realObject = {};
            for (let i = 0; i < object.length; i++) {
                realObject[i] = object[i];
            }
        }

        let results = {};
        for (const [key, value] of Object.entries(realObject)) {
            if (value instanceof Object && Object.keys(value).length > 0) {
                results = Object.assign(results, this.object_keys_with_brackets_for_formdata(value, prepend + key + (prepend ? ']' : '') + '['));
            } else {
                results[prepend + key + (prepend ? ']' : '')] = value;
            }
        }

        return results;
    }

    hide_tooltips() {
        window.scroll(window.scrollX, window.scrollY - 1);
        window.scroll(window.scrollX, window.scrollY + 1);
        document.querySelectorAll('.tooltip-old').forEach((e) => {
            e.style.visibility = 'hidden';
        })
    }

    str_capitalize(s) {
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    seconds_to_time(time) {
        const hours = Math.floor(time / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    human_readable_locale(locale) {
        return locale === 'any' ? 'EN' : locale.toUpperCase()
    }

    removeTooltips() {
        document.querySelectorAll('.tooltip-old').forEach(e => e.parentNode.removeChild(e))
    }
}

// Usage:
// this.$helper.log('test log');
export default {
    install(Vue) {
        Vue.prototype.$helper = new Helper();
    },

    // Vue.use(Helper, {
    //     data1: data1,
    //     data2: data2
    // });
    // install(Vue, options) {
    //     Vue.prototype.$helper = new Helper(options.data1, options.data2);
    // },
};
