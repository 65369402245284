import {
    cilLockLocked,
    cilSpeedometer,
    cilUser,
    cibGmail,
    cilCheckCircle,
    cilCircle,
    cilPencil,
    cilBan,
    cilReload,
    cilTrash,
    cilMenu,
    cilPuzzle,
    cilObjectGroup,
    cilFile,
    cilArrowBottom,
    cilArrowTop,
    cilChart,
    cilNotes,
    cilAddressBook,
    cilMediaPlay,
    cilCasino,
    cilVolumeHigh,
    cilXCircle,
    cilBarChart,
    cilImage,
    cilFullscreen,
    cilFullscreenExit,
    cilApplications,
    cilPeople,
} from '@coreui/icons'

const icons = {
    cilLockLocked,
    cilSpeedometer,
    cilUser,
    cibGmail,
    cilCheckCircle,
    cilCircle,
    cilPencil,
    cilBan,
    cilReload,
    cilTrash,
    cilMenu,
    cilPuzzle,
    cilObjectGroup,
    cilFile,
    cilArrowBottom,
    cilArrowTop,
    cilChart,
    cilNotes,
    cilAddressBook,
    cilMediaPlay,
    cilCasino,
    cilVolumeHigh,
    cilXCircle,
    cilBarChart,
    cilImage,
    cilFullscreen,
    cilFullscreenExit,
    cilApplications,
    cilPeople,
};

export default icons;
