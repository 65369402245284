import {
    HANDLE_DEFAULT_ERROR,
    DISPLAY_ALERT_DANGER,
} from '../../actionTypes';
import store from "../index";

export default {
    [HANDLE_DEFAULT_ERROR]({commit}, payload) {
        if (payload.error && payload.error.response.data && payload.error.response.data.errors) {
            let errorString = '';
            let timer = payload.timer ? payload.timer : 20;
            if (payload.message) {
                errorString = payload.message;
            } else {
                let errors = [];
                Object.keys(payload.error.response.data.errors).forEach((key) => {
                    errors = errors.concat(payload.error.response.data.errors[key]);
                });
                errorString = errors.length > 1 ? ('<ul><li>' + errors.join('</li><li>') + '</li></ul>') : errors.join('');
            }
            if (errorString) {
                if (payload.prefix) {
                    errorString = payload.prefix + errorString;
                }
                let dispatchEventName = payload.dispatch_event ? payload.dispatch_event : DISPLAY_ALERT_DANGER;
                store.dispatch(dispatchEventName, {text: errorString, timer: timer}).then(() => {
                    commit(errorString)
                });
            }
        }
    },
};
