import store from '../store/index'

class ConfigHelper {
    constructor(vue) {
        this.$vue = vue.prototype
    }

    listingStatuses() {
        return (store.state.config && store.state.config.listing_statuses ? store.state.config.listing_statuses : []).map(item => ({
            value: item.id,
            label: this.$vue.$helper.str_capitalize(item.key)
        }))
    }

    reviewStatuses() {
        let statuses = store.state.config && store.state.config.review_statuses ? store.state.config.review_statuses : {};
        let result = [];
        Object.keys(statuses).forEach((k) => {
            result.push({
                label: this.$vue.$helper.str_capitalize(statuses[k]['key']),
                value: parseInt(k),
            })
        })
        return result
    }

    isProduction() {
        return store.state.config?.is_production ?? true;
    }
}

export default {
    install(Vue) {
        Vue.prototype.$config = new ConfigHelper(Vue);
    },
};
