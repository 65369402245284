const Index = () => import('../../components/menu/ApiKeys/ApiKeysIndex')

export default {
    path: 'api-keys',
    redirect: 'api-keys',
    component: {
        template: '<router-view></router-view>',
    },
    name: 'api-keys',
    meta: {
        // 'permissions': 'view-users',
    },
    children: [
        {
            path: '/',
            component: Index,
        },
    ],
};
