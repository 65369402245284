import {PUSH_ALERT, SET_AUTH_TOKEN, SET_CONFIG, SET_USER} from '../mutationTypes';
import store from "./index";

export default {
    // Core-ui
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },

    // Custom
    [SET_AUTH_TOKEN](state, payload) {
        let token = payload && payload?.access_token ? payload.access_token : null;
        let type = payload && payload.token_type ? payload.token_type : null;

        if (token && type) {
            window.localStorage.setItem(store.state.local_storage_access_token_key, token);
            window.localStorage.setItem(store.state.local_storage_token_type_key, type);
        } else {
            window.localStorage.removeItem(store.state.local_storage_access_token_key);
            window.localStorage.removeItem(store.state.local_storage_token_type_key);
        }
    },
    [SET_USER](state, payload) {
        state.user = typeof payload === 'object' && payload !== null ? payload : {};
    },
    [PUSH_ALERT](state, payload) {
        state.alerts.push(payload);
    },
    [SET_CONFIG](state, payload) {
        state.config = payload;
    },
};
