export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER = 'FETCH_USER';
export const LOGIN_IF_AUTHENTICATED = 'LOGIN_IF_AUTHENTICATED';
export const FETCH_CONFIG = 'FETCH_CONFIG';

export const HANDLE_DEFAULT_ERROR = 'HANDLE_DEFAULT_ERROR';

// alerts
export const DISPLAY_ALERT = 'DISPLAY_ALERT';
export const DISPLAY_ALERT_SUCCESS = 'DISPLAY_ALERT_SUCCESS';
export const DISPLAY_ALERT_WARNING = 'DISPLAY_ALERT_WARNING';
export const DISPLAY_ALERT_INFO = 'DISPLAY_ALERT_INFO';
export const DISPLAY_ALERT_DANGER = 'DISPLAY_ALERT_DANGER';
