import { render, staticRenderFns } from "./Alerts.vue?vue&type=template&id=e0e38fea&scoped=true"
import script from "./Alerts.vue?vue&type=script&lang=js"
export * from "./Alerts.vue?vue&type=script&lang=js"
import style0 from "./Alerts.vue?vue&type=style&index=0&id=e0e38fea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../.npm/_npx/14f5bcddd9f17f34/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0e38fea",
  null
  
)

export default component.exports