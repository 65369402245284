import store from "../store";
import {LOGOUT_USER} from "../actionTypes";

class User {
    check() {
        return Object.keys(store.state.user).length > 0
    }

    logout() {
        return new Promise((resolve, reject) => {
            store.dispatch(LOGOUT_USER)
              .then(response => {
                  resolve(response)
              })
              .catch(err => {
                  reject(err);
              });
        });
    }

    get(property, defaultValue = null) {
        if (property) {
            return this.get()[property] ?? defaultValue;
        }
        return store.state.user;
    }

    can(permission) {
        return (store.state.user.permissions ? store.state.user.permissions : []).includes(permission)
    }

    canAll(permissions) {
        for(let i = 0; i < permissions.length; i++) {
            if (!this.can(permissions[i])) {
                return false;
            }
        }
        return true;
    }

    canAny(permissions) {
        for(let i = 0; i < permissions.length; i++) {
            if (this.can(permissions[i])) {
                return true;
            }
        }
        return false;
    }
}

// Usage:
// this.$user.can('view-dashboard');
export default {
    install(Vue) {
        Vue.prototype.$user = new User();
    },
};
