import {
    DISPLAY_ALERT,
    DISPLAY_ALERT_DANGER,
    DISPLAY_ALERT_INFO,
    DISPLAY_ALERT_SUCCESS,
    DISPLAY_ALERT_WARNING
} from '../../actionTypes';
import {PUSH_ALERT} from '../../mutationTypes';
import store from "../index";

export default {
    [DISPLAY_ALERT]({commit}, payload) {
        let data = {
            color: 'info',
            show_progress: true,
            show_alert_disappear_message: true,
            timer: 5,
        }
        Object.assign(data, payload);
        commit(PUSH_ALERT, data);
    },
    [DISPLAY_ALERT_SUCCESS]({commit}, payload) {
        Object.assign(payload, {color: 'success'});
        store.dispatch(DISPLAY_ALERT, payload);
    },
    [DISPLAY_ALERT_INFO]({commit}, payload) {
        Object.assign(payload, {color: 'info'});
        store.dispatch(DISPLAY_ALERT, payload);
    },
    [DISPLAY_ALERT_WARNING]({commit}, payload) {
        Object.assign(payload, {color: 'warning'});
        store.dispatch(DISPLAY_ALERT, payload);
    },
    [DISPLAY_ALERT_DANGER]({commit}, payload) {
        Object.assign(payload, {color: 'danger'});
        store.dispatch(DISPLAY_ALERT, payload);
    },
};
