class ObjectHelper {
    only(obj, keys) {
        const res = {};
        keys.forEach((key) => {
            if (obj[key] !== undefined) {
                // noinspection EqualityComparisonWithCoercionJS
                res[key] = Number(obj[key]) == obj[key] ? Number(obj[key]) : obj[key];
            }
        })
        return res;
    }

    are_same(obj1, obj2) {
        if (Object.keys(obj1).length === Object.keys(obj2).length) {
            // noinspection EqualityComparisonWithCoercionJS
            return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj2[key] == obj1[key]);
        }
        return false;
    }

    except(obj, array) {
        let clone = Object.assign({}, obj);
        array.forEach((key) => {
            delete clone[key];
        })
        return clone;
    }

    buildQuery(obj) {
        let query = '';
        Object.keys(obj).forEach(key => {
            query += (query ? '&' : '?') + key + '=' + obj[key];
        });
        return query;
    }
}

export default {
    install(Vue) {
        Vue.prototype.$object = new ObjectHelper();
    },
};
