export default {
    // core-ui
    sidebarShow: 'responsive',
    sidebarMinimize: false,

    local_storage_access_token_key: 'auth:access_token',
    local_storage_token_type_key: 'auth:token_type',

    user: {},
    config: {},
    alerts: [],
};
